'use strict'
// A sample component

const ScrollTop =  {
    el: null,
    init: function(){
        ScrollTop.el = document.querySelector('.c-scrollTop');

        if (ScrollTop.el){
            let previousScrollPosition = 0;

            // Button appears at the end of the page or when scrolling up
            window.addEventListener('scroll', () => {
                if( window.scrollY < previousScrollPosition 
                    || window.scrollY + + window.innerHeight + 100 >= document.body.scrollHeight)
                {
                    ScrollTop.el.classList.add('active')
                }
                else {
                    ScrollTop.el.classList.remove('active')
                }
                previousScrollPosition = window.scrollY
            })

            // Click event
            ScrollTop.el.addEventListener('click', () => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            })
        }
    }
}


export default ScrollTop;
