'use strict'

// A sample component

const Contact =  {
    fullUrl : data_api['url_contact'],
    popinContact:null,
    form: null,
    init: function(){
        Contact.popinContact = document.querySelector('.c-contact');
        if(Contact.popinContact) {
            this.event();
        }
    },
    event: function() {
        document.querySelectorAll('.c-contact_toggle').forEach(function(item) {
            item.addEventListener('click', function(e) {
                e.preventDefault();
                Contact.popinContact.classList.add('open--');
                document.body.style.overflow = "hidden";
            });
        })

        Contact.popinContact.querySelector('.c-contact__close').addEventListener('click', function(e) {
            e.preventDefault();
            Contact.popinContact.classList.remove('open--');
            document.body.style.overflow = "auto";
        });



        Contact.popinContact.querySelector('.c-contact__overlay').addEventListener('click', function(e) {
            e.preventDefault();
            Contact.popinContact.classList.remove('open--');
            document.body.style.overflow = "auto";
        });
    }
}

export default Contact;