'use strict'
const axios = require("axios");

const Modal =  {
    elts: null,
    modal: null,
    modalContent: null,
    modalLoader: null,
    modalClose: null,
    modalDot:null,
    modalNext: null,
    modalPrev:null,
    slideIndex:1,

    init: function(){
        Modal.modal = document.querySelector('.js-modal__modal');
        Modal.elts = document.querySelectorAll('.js-modal__item')
        if(Modal.modal && Modal.elts && Modal.elts.length > 0){
            Modal.modalContent = Modal.modal.querySelector('.js-modal__slider');
            Modal.modalLoader = Modal.modal.querySelector('.js-modal__modal--loader');
            Modal.modalClose = Modal.modal.querySelector('.js-modal__modal--close-btn');
            Modal.modalDot = Modal.modal.querySelector('.js-modal__dot')
            Modal.modalNext = Modal.modal.querySelector('.js-modal__next')
            Modal.modalPrev = Modal.modal.querySelector('.js-modal__prev')

            if(Modal.modalContent) {
                Modal.event();
                Modal.removeSlides();
            }
        }
    },
    event: function() {
        Modal.elts.forEach( item => {
            item.addEventListener('click', () => {
                if(item.dataset.apiDetail) {
                    Modal.showModal(item.dataset.apiDetail)
                }
            });
        });

        if(Modal.modalClose) {
            Modal.modalClose.addEventListener('click', () => {
                Modal.hideModal()
            })
        }

        ///slider
        Modal.modalNext.addEventListener('click', (e) => {
            Modal.nextPrevSlide(1)
        })
        Modal.modalPrev.addEventListener('click', (e) => {
            Modal.nextPrevSlide(-1)
        })
    },

    showModal: function (api){
        Modal.modalLoader.classList.remove('hide--');
        Modal.modal.classList.add('open');

        axios({
            method: "get",
            url: api,
            // url:"//localhost:3000/wp-json/havas-group/v2/client-case-detail/103RR11?lang=en"
        }).then(function (response) {
            Modal.modalLoader.classList.add('hide--');
            if(response.status === 200) {
                const data = response.data;
                if(data && data.length > 0) {
                    Modal.showResults(data);
                }
                else {
                    Modal.noResults();
                }
            }
            else {
                Modal.noResults()
            }
        }).catch(function (response) {
            //handle error
            console.log('err catch apiDetails', response);

            Modal.modalLoader.classList.add('hide--');
            Modal.noResults()
        });
    },
    showResults: (data) => {
        let html='';
        let dot='<ul>';
        let i = 1;
        data.forEach((elt) => {
            html+=`<div class="js-modal__slider--slide">`;
            if(elt.type==="video") {
                html+=`
                <div class="m-video_player">
                    <div class="m-video_iframe">
                        <div videoID=${elt.id_video_dailymotion} autoplay="0" height="640" class="m-video_dailymotion dailymotion_player"></div>
                    </div>
                </div>`;
            }
            else if (elt.type==="picture") {
                html+=`<div class="js-modal__modal--img" style="background-image:url(${elt.picture.sizes['picture-gallery-client-case']})"></div>`
            }
            html +="</div>";
            dot +=`<li data-index=${i}><span></span></li>`;
            i++;
        })
        dot +='</ul>';

        Modal.modalContent.innerHTML = html;
        Modal.modalDot.innerHTML = dot;
        Modal.initSlider();
        
        if(tarteaucitron.state.dailymotion) {
        // Event to reinit Tarteaucitron iframe generation
            const evt = new Event('generateDailymotionIframe');
            window.dispatchEvent(evt);
        }
        else {
            const evt = new Event('fallBackDailymotionIframe');
            window.dispatchEvent(evt);
        }
        

    },
    initSlider: () => {
        Modal.slideIndex = 1;
        Modal.showSlides(1);
        Modal.modalDot.querySelectorAll("li").forEach((dot) => {
            dot.addEventListener('click', (e) => {
                if(e.currentTarget.dataset.index) {
                    Modal.showSlides(e.currentTarget.dataset.index)
                }
            })
        });
        
        if(Modal.modalDot.querySelectorAll("li").length > 1) {
            Modal.modalNext.classList.add('show--');
            Modal.modalPrev.classList.add('show--');
        }
    },
    nextPrevSlide: (n) => {
        Modal.showSlides(Modal.slideIndex += n);
    },
    showSlides(n) {
        let slides = Modal.modalContent.querySelectorAll(".js-modal__slider--slide");
        let currentSlide = n
        if (n > slides.length) { currentSlide = 1; }    
        if (n < 1) { Modal.slideIndex = currentSlide = slides.length}
        Modal.slideIndex = currentSlide;

        if(Modal.modalContent.querySelector(".js-modal__slider--slide.show--")) {
            const oldItem = Modal.modalContent.querySelector(".js-modal__slider--slide.show--");
            
            if (oldItem.querySelector('.m-video_dailymotion') && oldItem.querySelector('.m-video_dailymotion iframe')) {
                console.log(oldItem.querySelector('.m-video_dailymotion'));
                const src = oldItem.querySelector('.m-video_dailymotion iframe').src;
                oldItem.querySelector('.m-video_dailymotion iframe').src = "";
                oldItem.querySelector('.m-video_dailymotion iframe').src = src;
            }
            oldItem.classList.remove('show--');
        }
        if(Modal.modalDot.querySelector("li.active")) {
            Modal.modalDot.querySelector("li.active").classList.remove("active");
        }
        
        if(Modal.modalContent.querySelector(`.js-modal__slider--slide:nth-child(${currentSlide}`))
            Modal.modalContent.querySelector(`.js-modal__slider--slide:nth-child(${currentSlide}`).classList.add("show--");
        if(Modal.modalDot.querySelector(`li:nth-child(${currentSlide}`))
            Modal.modalDot.querySelector(`li:nth-child(${currentSlide}`).classList.add("active");
    },
    stopVideo:(elt) => {
        // elt.querySelector
    },
    removeSlides:() => {
        Modal.modalContent.innerHTML = "";
        Modal.modalDot.innerHTML = "";
        Modal.slideIndex = 0;
        Modal.modalNext.classList.remove('show--');
        Modal.modalPrev.classList.remove('show--');
    },
    hideModal : () => {
        Modal.modal.classList.remove('open');
        setTimeout(() => {
            Modal.removeSlides();
            Modal.modalLoader.classList.add('show--');
        }, 200)
    },
    noResults: () => {
        Modal.modalContent.innerHTML = `<div class="slider__item js-modal__modal--results"><p>${Modal.modal.dataset.noresults}</p></div>`;
        Modal.modalDot.innerHTML = "";
        Modal.slideIndex = 0
    }
}

export default Modal;