'use strict'
const axios = require("axios");
import Chart from 'chart.js/auto';

const BourseChart = {
    eltBourseChart: null,
    apiBourse: data_api['url_bourse_graphique'],
    filtersInitialized:false,
    init: function () {
        BourseChart.eltBourseChart = document.querySelectorAll('.f-bourseChart');
        if (BourseChart.apiBourse && BourseChart.eltBourseChart && BourseChart.eltBourseChart.length > 0) {
            BourseChart.eltBourseChart.forEach(elt => {
                BourseChart.loadBourse(elt);
            });
        }
    },

    loadBourse: function (elt, period = '') {
        const params= period ? `&period=${period}` : "";
        
        const url = new URL(BourseChart.apiBourse);
        const lang = url.searchParams.get('lang') || 'fr';
        axios({
            method: "post",
            url: `${BourseChart.apiBourse}${params}`
        })
        .then(response => {
            // console.log(response);
            elt.querySelector('canvas').classList.add('show--');
            elt.querySelector('.c-loader').classList.add('hide--');

            // Affiche les filtres une seule fois
            if (response.data.periodes) {
                BourseChart.showFilters(elt, response.data.periodes, response.data.periode_active);
                BourseChart.filtersInitialized = true;
            }
            BourseChart.showChart(elt, response.data.label, response.data.value, lang);
        })
        .catch(error => {
            BourseChart.showErreur();
            console.log('Erreur lors du chargement des données', error);
        });
    },
    showFilters: function (elt, filters, active) {
        const filterContainer = elt.querySelector('.f-bourseChart__filters');
        if (filterContainer && !elt.filtersInitialized) {
            filterContainer.innerHTML = '';
    
            for (const key in filters) {
                if (filters.hasOwnProperty(key)) {
                    const value = filters[key];
                    const listItem = document.createElement('li');
                    listItem.innerHTML = `<button class="f-bourseChart__filter${active == key ? " active--" : ''}" data-value="${key}">${value}</button>`;
                    filterContainer.appendChild(listItem);
                }
            }
    
            BourseChart.eventFilters(elt);
            elt.filtersInitialized = true;
        }
    },
    
    eventFilters: function (elt) {
        elt.querySelectorAll('.f-bourseChart__filters .f-bourseChart__filter').forEach(filter => {
            filter.addEventListener('click', function () {

                elt.querySelectorAll('.f-bourseChart__filters .f-bourseChart__filter').forEach(btn => {
                    btn.classList.remove('active--');
                });
                this.classList.add('active--');

                elt.querySelector('canvas').classList.remove('show--');
                elt.querySelector('.c-loader').classList.remove('hide--');
                const selectedValue = this.dataset.value;
                BourseChart.loadBourse(elt, selectedValue);
            });
        });
    },

    showChart: function(elt, labels, dataPoints, lang) {//
        const canvas = elt.querySelector('canvas');
        const color = elt.dataset.themeColor === "white-theme" ? "rgb(100, 100, 100)" : "rgb(255, 255, 255)";
        // console.log(color)

        // const labels = ['1', '2', '3', '4', '5', '6', '7'];
        // const dataPoints = [65, 59, 80, 81, 56, 55, 70];

        const showAllLabels = elt.querySelector('.f-bourseChart__filters .f-bourseChart__filter.active--') && elt.querySelector('.f-bourseChart__filters .f-bourseChart__filter.active--').dataset.value === "1W";
        // console.log(showAllLabels)
        // console.log(labels, dataPoints)
        if (elt.chartInstance) {
            elt.chartInstance.destroy();
        }

        if (canvas) {
            const ctx = canvas.getContext("2d");
        
            // Correction du pixel ratio
            canvas.width = canvas.clientWidth * window.devicePixelRatio;
            canvas.height = canvas.clientHeight * window.devicePixelRatio;
            ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
        
            elt.chartInstance = new Chart(canvas, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        data: dataPoints,
                        fill: true, 
                        borderColor: color,
                        tension: undefined,
                        pointRadius: 2,
                        pointHoverRadius: 5,
                        pointBackgroundColor: color
                    }]
                },
                options: {
                    interaction: {
                        mode: 'point',
                        intersect: true,
                        axis: 'xy'
                    },
                    scales: {
                        y: {
                            min: Math.min(...dataPoints),
                            grid: {
                                drawBorder: false, 
                                color: color,
                                drawTicks: false
                            },
                            ticks: {
                                color: color,
                                padding:10,
                                callback: function(value) {
                                    return formatNumber(value, lang);
                                }
                            }
                        },
                        x: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                color: color,
                                callback: function(value, index) {
                                    if (showAllLabels) {
                                        return this.getLabelForValue(value);
                                    }
                                    return index % 10 === 0 ? this.getLabelForValue(value) : '';
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true,
                            mode: 'index',
                            intersect: true,
                            callbacks: {
                                title: () => '',
                                label: function(tooltipItem) {
                                    return tooltipItem.raw.toString();
                                }
                            }
                        }
                    },
                    elements: {
                        line: {
                            backgroundColor: (context) => {
                                const chart = context.chart;
                                const { ctx, chartArea } = chart;
            
                                if (!chartArea) return null; 
            
                                const yAxis = chart.scales.y;
                                const gradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);
                                gradient.addColorStop(0, "rgba(20, 85, 102, 1)");
                                gradient.addColorStop(1, "rgba(20, 85, 102, 0)");
            
                                return gradient;
                            }
                        }
                    },
                    responsive: true, 
                    maintainAspectRatio: true
                },
                plugins: [verticalLinePlugin]
            });
        
            // Correction des coordonnées de la souris pour éviter le décalage
            canvas.addEventListener('mousemove', (event) => {
                const rect = canvas.getBoundingClientRect();
                const mouseX = (event.clientX - rect.left) * window.devicePixelRatio;
                const mouseY = (event.clientY - rect.top) * window.devicePixelRatio;
        
                const points = elt.chartInstance.getElementsAtEventForMode(
                    { offsetX: mouseX, offsetY: mouseY },
                    'point',
                    { intersect: true },
                    false
                );
        
                // console.log('Mouse:', mouseX, mouseY);
                // console.log('Points détectés:', points);
            });
        }
    },

    showErreur: function() {
        BourseChart.eltBourseChart.forEach(elt => {
            elt.querySelector('.c-loader').classList.add('hide--');
            elt.querySelector('.f-bourseChart__content').innerHTML = "Error";
        })
    }
};


// Création de barres verticales
const verticalLinePlugin = {
    id: "verticalLines",
    beforeDraw: (chart) => {
        const ctx = chart.ctx;
        const xAxis = chart.scales.x;
        const yAxis = chart.scales.y;

        ctx.save();
        ctx.strokeStyle = "rgba(255, 255, 255, 0.8)";
        ctx.lineWidth = 2;

        const firstX = xAxis.getPixelForValue(0);
        const lastX = xAxis.getPixelForValue(chart.data.labels.length - 1);
        const bottomY = yAxis.getPixelForValue(yAxis.min);

        ctx.beginPath();
        ctx.moveTo(firstX, bottomY);
        ctx.lineTo(firstX, yAxis.getPixelForValue(chart.data.datasets[0].data[0]));
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(lastX, bottomY);
        ctx.lineTo(lastX, yAxis.getPixelForValue(chart.data.datasets[0].data[chart.data.datasets[0].data.length - 1]));
        ctx.stroke();

        ctx.restore();
    }
};

const formatNumber = (num,lang) => {
    return lang === "fr" ? num.toString().replace('.', ',') : num.toString().replace(',', '.');
};

export default BourseChart;