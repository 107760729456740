'use strict'
import 'intersection-observer';
const riselinear = {
  elements: document.querySelectorAll('.js-data-rise-linear'),
  options: {threshold: [0.2, 0.55, 0.80, 1]},
  observer:'',

  init: function(){
    if(document.querySelectorAll('.js-data-rise-linear')) {
      riselinear.observer = new IntersectionObserver(riselinear.action, riselinear.options);
      for (let element of this.elements) {
        this.prepare(element)
        riselinear.observer.observe(element);
      }
    }
  },

  prepare: function(item){
    item.querySelectorAll('.js-elt').forEach(function(elt) {
      if(document.body.clientWidth<=1024) {
        elt.style.transform = 'translateX(5rem)'
      }
      else {
        elt.style.transform = 'translateX(12rem)'
      }
      elt.style.opacity= '0'
      elt.style.transition = 'all 0.5s ease-out, font-size .3s ease-in'
    })
    
  },

  action: function(items){
    items.forEach((item) => {
      if (item.intersectionRatio > 0.1){
        var timer = new Array();
        item.target.querySelectorAll('.js-elt').forEach(function(elt, index) {
          var delay=100*index*2.5;
          timer[index] = setTimeout(function() {
            elt.style.transform = 'translateX(0)'
            elt.style.opacity= '1'
            clearTimeout(timer[index]);
          }, delay);
        });
        riselinear.observer.unobserve(item.target);
      }
    })
  }
}

export default riselinear
