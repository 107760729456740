'use strict'

const Accordeon =  {
    init: function(){
        this.accordeonOnclick();
    },

    accordeonOnclick: function() {
        const accordeons = document.querySelectorAll('.f-accordeon_list');
        if (accordeons.length > 0) {
            accordeons.forEach(accordeon => {
                const accordeonItems = accordeon.querySelectorAll('.f-accordeon_item');
                if (accordeonItems.length > 0) {
                    accordeonItems.forEach(item => {
                        item.addEventListener('click', ()=> {
                            const isOpen = item.classList.contains('open');
                            accordeonItems.forEach(item => {
                                item.classList.remove('open');
                            });
                            if (!isOpen) {
                                item.classList.add('open');
                            }
                        })
                    })
                }
            })
        }
    },
}

export default Accordeon;