'use strict'

// A sample component

const Header =  {
    head: null,
    scrollUp: "scroll-up",
    scrollDown: "scroll-down",
    lastScroll: 0,
    init: () => {
        const header = document.querySelector('header');
        if(header && window.matchMedia("(max-width: 991px)").matches) {
            Header.head = header;
            Header.event();
        }
    },
    event: () => {
        window.addEventListener("scroll", () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 0) {
                Header.head.classList.remove('sticky--');
            return;
            }
            if (currentScroll > Header.lastScroll && Header.head.classList.contains('sticky--'))  // down
            {
                Header.head.classList.remove('sticky--');
            } else if ( currentScroll < Header.lastScroll && !Header.head.classList.contains('sticky--')) // up
            { 
                Header.head.classList.add('sticky--');
            }
            Header.lastScroll = currentScroll;
        });
    }
};

export default Header;