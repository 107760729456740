'use strict';
// import customSelect from 'custom-select';
import TabModuleAnimations from './textAppear'


const Prosumer = {
    fullUrl : data_api['url_prosumer_reports'],
    filters: new Array(),
    resultsAll: new Array,
    resultsfilters: new Array,
    resultsElt:null,
    nbResultsElt:null,
    formElt:null,
    filterElt: null,
    step: 6,
    currentStep:0,
    nameBtn: '',
    imgDefault: '',
	init: function(){
        const pressElt = document.querySelector('.c-prosumer');
        if(pressElt) {
            Prosumer.nameBtn = pressElt.querySelector('.c-prosumer__results').dataset.btnname;
            Prosumer.imgDefault = pressElt.querySelector('.c-prosumer__results').dataset.imgdefault;
            Prosumer.resultsElt = pressElt.querySelector('.c-prosumer__content');
            Prosumer.nbResultsElt = pressElt.querySelector('.c-prosumer__nbresults');
            Prosumer.filterElt = pressElt.querySelector('.c-prosumer__filter--list');
            Prosumer.formElt = pressElt.querySelector('.c-prosumer__form');
            Prosumer.eventsLoad();
            Prosumer.loadJson(); //chargement du json
            TabModuleAnimations.init();
        }
    },
    eventsLoad:function() {
        Prosumer.formElt.addEventListener('submit', (e) => {
            e.preventDefault();
            Prosumer.resultsElt.innerHTML='';
            Prosumer.resultsElt.classList.remove('show--');
            document.querySelector('.c-loader').classList.remove('hide--');
            Prosumer.nbResultsElt.classList.remove('show--');
            if(Prosumer.filterElt.querySelector('.c-select')) {
                Prosumer.filterElt.querySelector('.c-select').remove();
            }
            Prosumer.loadJson(Prosumer.formElt.querySelector('input:not([type="submit"])').value);
        })
    },
    events: function() {
        Prosumer.filterElt.querySelectorAll('select').forEach((select) => {
            select.addEventListener('change', function(e) {
                Prosumer.resultsElt.innerHTML='';
                Prosumer.resultsElt.classList.remove('show--');
                document.querySelector('.c-loader').classList.remove('hide--');
                Prosumer.nbResultsElt.classList.remove('show--');
                Prosumer.resultsfilters=Prosumer.resultsAll.filter(Prosumer.applyFilter);
                Prosumer.currentStep=0;
                window.scrollTo({
                    behavior: 'smooth',
                    top: 0,
                 });
                Prosumer.nbResults(Prosumer.resultsfilters.length);
                Prosumer.showResults(Prosumer.resultsfilters)
            });
        });

        window.addEventListener('scroll', function(e) {
            if(document.querySelector('.c-loader:not(.hide--)')) {
                var positionLoader = document.querySelector('.c-loader').getBoundingClientRect();
                if( positionLoader.top<= window.innerHeight) {
                    Prosumer.showResults(Prosumer.resultsfilters);
                }
            }
        });

        document.querySelector('.c-loader').addEventListener('click', function(e) {
            e.preventDefault();
            Prosumer.showResults(Prosumer.resultsfilters);
        })
    },
    applyFilter:function(obj) {
        var status=true;
        document.querySelectorAll('.c-select select').forEach(function(item) {
            //filtre par date
            if(item.name=="filteryear" && item.value!="all" && status==true) {
                status=false;
                if(item.value==obj.year) {
                    status=true;
                }
            }
        });
        return status;
    },
    loadJson: function(search='') {
        let request = new XMLHttpRequest();
		request.open("GET", Prosumer.fullUrl+`&title=${search}`);
		request.onreadystatechange = function () {
			if (request.readyState == 4 && request.status == 200) {
                var data = JSON.parse(request.responseText);
                Prosumer.filters = data.filters;
                Prosumer.resultsAll = data.content;
                Prosumer.resultsfilters = data.content;
                Prosumer.currentStep = 0;
                Prosumer.showFilter(); //affiche les filtres
                Prosumer.nbResults(data.content.length);
                Prosumer.showResults(data.content); //affiche tous les résultats
            }
		};
		request.send();
    },
    showFilter: function() {
        if(Prosumer.filters)
        {
            for(var key in Prosumer.filters) {
                var filtre = Prosumer.filters[key]
                if(filtre) {
                    var items = "";
                    var count=0;
                    for(var cle in filtre) {
                        items+='<option value="'+cle+'">'+filtre[cle]+'</option>';
                        count++;
                    }
                    if(count>1) {
                        Prosumer.filterElt.insertAdjacentHTML('beforeend', `<div class="c-select js-elt" data-cursor-hover><select name="filter${key}">${items}</select></div>`);
                    }
                }
            }
            
            var timer=setTimeout(function() {
                Prosumer.prettyShowFilter(Prosumer.filterElt);
                Prosumer.events();
                clearTimeout(timer);
            }, 200)
            
        }
    },
    showResults: function(resultsList) {
        var items = "";
        var endSearch=resultsList.length;
        if(Prosumer.currentStep + Prosumer.step <= endSearch) {
            endSearch = Prosumer.currentStep + Prosumer.step;
        }
        for(var i= Prosumer.currentStep; i < endSearch; i++) {
            items +=`
            <div class="f-txtVisuel small-wrapper c-prosumer__item js-slide-in">
                <div class="f-txtVisuel_content">
                    <div class="f-txtVisuel_picture">
                        <div class="f-txtVisuel_picture_wrapper">
                            <img class="js-clipped-img" src="${resultsList[i].image.url!='' ? resultsList[i].image.url : Prosumer.imgDefault}" alt="${resultsList[i].image.title!='' ? resultsList[i].image.title : resultsList[i].title}" loading="lazy">
                        </div>
                    </div>
                    <div class="f-txtVisuel_wysiwyg c-wysiwyg js-text-element appear"><span class="js-span">
                        <h3>${resultsList[i].title}</h3>
                        <p>${resultsList[i].description}</p>
                        ${resultsList[i].cta.pdf!='' &&
                            `<div><a class="btn red" href="${resultsList[i].cta.pdf}" target="_blank" rel="noopener"><strong><span class="emptyClass">${resultsList[i].cta.label!='' ? resultsList[i].cta.label : Prosumer.nameBtn}</span></strong><span class="btn-arrow">&nbsp;</span></a></div>`
                        }
                    </span></div>
                </div>
            </div>`
        }

        Prosumer.currentStep +=Prosumer.step;
        Prosumer.resultsElt.insertAdjacentHTML('beforeend', items);
        
        Prosumer.resultsElt.classList.add('show--');
        if(endSearch >= resultsList.length) {
            document.querySelector('.c-loader').classList.add('hide--');
        }
        
        TabModuleAnimations.blockApparitionSlideIn();
        
    },
    nbResults:function(number) {
        Prosumer.nbResultsElt.querySelector('span').innerHTML = number;
        Prosumer.nbResultsElt.classList.add('show--');
    },
    prettyShowFilter:function(filterElt) {
        filterElt.parentNode.classList.add('show--');
        var timer = new Array();
        Prosumer.filterElt.querySelectorAll('.js-elt').forEach(function(elt, index) {
            var delay=100*index*1.5;
            timer[index] = setTimeout(function() {
                elt.style.transform = 'translateY(0)';
                elt.style.opacity= '1';
            }, delay);
        });
    }
};

export default Prosumer;