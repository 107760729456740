'use strict'
const axios = require("axios");
const Bourse = {
    eltBourse:null,
    apiBourse: data_api['url_bourse'], //recuperation url bourse api
    init: function () {
        Bourse.eltBourse = document.querySelectorAll('.f-bourse');
        if(Bourse.apiBourse && Bourse.eltBourse && Bourse.eltBourse.length > 0) {
            Bourse.loadBourse();
        }
    },
    loadBourse: function() {
        axios({
            method: "post",
            url: Bourse.apiBourse
        }).then(function (response) {
            Bourse.showResults(response.data);
        }).catch(function (response) {
            //handle error
            Bourse.eltBourse.forEach(elt => {
                elt.querySelector('.f-bourse__ctn').classList.add('show--');
                elt.querySelector('.c-loader').classList.add('hide--');
                Bourse.showErreur();
            })
            console.log('err catch getBourse', response);
        });
    },
    showResults: function(data) {
        data ?
            Bourse.eltBourse.forEach(elt => {
                elt.querySelector('.f-bourse__ctn').classList.add('show--');
                elt.querySelector('.c-loader').classList.add('hide--');
                if(elt.querySelector('.f-bourse__intro')) {
                    let htmlIntro = `<ul>
                        ${data.AEX ? `<li>${data.AEX.nom} ${data.AEX.variation}% 
                            <span class="f-bourse__icon ${parseFloat(data.AEX.variation.replace(',', '.')) < 0 ? `negatif--` : ''}"></span>
                        </li>` : ''}
                        ${data.AMX ? `<li>${data.AMX.nom} ${data.AMX.variation}% 
                            <span class="f-bourse__icon ${parseFloat(data.AMX.variation.replace(',', '.')) < 0 ? `negatif--` : ''}"></span>
                        </li>` : ''}
                    </ul>`;
                    elt.querySelector('.f-bourse__intro').innerHTML = htmlIntro;
                }

                let html = `${data.nom && data.nom.value ? `<h3 class="h3 action_name">${data.nom.value}</h3>` : ''}
                    ${data.date && data.date.value ? `<p class="action_date">${data.date.value} CET</p>` : ''}
                    <hr class="f-bourse__separator" />
                    ${data.cours && data.cours.value ? `<p class="action_cote">${data.cours.value}€</p>` : ''}
                    ${data['variation_mt'] && data['variation_mt'].value ? `<p class="action_plusmoins">
                        ${(parseFloat(data['variation_mt'].value.replace(',', '.')) > 0 ? '+' : '') + data['variation_mt'].value}€
                    </p>` : ''}`

                elt.querySelector('.f-bourse__global').innerHTML = html;

                let htmldetails =`<ul>
                    ${data['variation_percent'] ? `<li>${data['variation_percent'].label}<span>${data['variation_percent'].value}%</span></li>`: ''}
                    ${data.cloture ? `<li>${data.cloture.label}<span>${data.cloture.value}€</span></li>`: ''}
                    ${data.ouverture ? `<li>${data.ouverture.label}<span>${data.ouverture.value}€</span></li>`: ''}
                    ${data.jplushaut ? `<li>${data.jplushaut.label}<span>${data.jplushaut.value}€</span></li>`: ''}
                    ${data.jplusbas ? `<li>${data.jplusbas.label}<span>${data.jplusbas.value}€</span></li>`: ''}
                    ${data.volumejour ? `<li>${data.volumejour.label}<span>${data.volumejour.value}</span></li>`: ''}
                </ul>`;
                elt.querySelector('.f-bourse__details').innerHTML = htmldetails;
            })
        :
            Bourse.showErreur();
    },
    showErreur: function() {
        Bourse.eltBourse.forEach(elt => {
            elt.querySelector('.f-bourse__ctn').classList.add('show--');
            elt.querySelector('.c-loader').classList.add('hide--');
            elt.querySelector('.f-bourse__global').innerHTML = "Error";
        })
    }
};

export default Bourse;

