'use strict'

// A sample component

const Menu =  {
    app:null,
    burgerElt: null,
    navElt: null,
    navItemsElt: null,
    init: () => {
        let burger = document.querySelector('.burger-menu');
        if(burger) {
            Menu.burgerElt = burger;
            Menu.navElt = document.querySelector('.c-menu__list');
            Menu.navItemsElt = document.querySelectorAll('.c-menu__list > li');
            Menu.app = document.querySelector('.app');
            Menu.event();
            if (window.matchMedia("(max-width: 991px)").matches) {
                Menu.addBtnBack();
            }
        }
    },
    event:() => {
        Menu.burgerElt.addEventListener('click', (e)=> {
            e.preventDefault();
            if(Menu.app.classList.contains('open--')) { //close menu
                Menu.burgerElt.classList.remove('open--');
                Menu.app.classList.remove('open--');
                document.body.style.overflow = "auto";
                document.documentElement.style.overflow = 'auto'
                Menu.navElt.classList.remove('hasHover--');
                if(Menu.navElt.querySelector('li.hover--')) {
                    Menu.navElt.querySelector('li.hover--').classList.remove('hover--');
                }
            }
            else{ //open menu
                Menu.burgerElt.classList.add('open--');
                Menu.app.classList.add('open--');
                document.body.style.overflow = "hidden";
                document.documentElement.style.overflow = 'hidden'
            }
        })
        let typeEvent = "click";
        if (!window.matchMedia("(max-width: 991px)").matches) {
            typeEvent = "mouseover";
        }

        Menu.navItemsElt.forEach((elt) => {
            elt.querySelector('a').addEventListener(typeEvent, (e) => {
                if(Menu.navElt.querySelector('li.hover--')) {
                    Menu.navElt.querySelector('li.hover--').classList.remove('hover--');
                }
                elt.classList.add('hover--');
                if(elt.classList.contains('menu-item-has-children')) {
                    e.preventDefault();
                    Menu.navElt.classList.add('hasHover--')
                }
            })
            if(elt.classList.contains('menu-item-has-children')) {
                elt.querySelector('a').addEventListener('click', (e) => {
                    e.preventDefault();
                });
            }
        });
    },
    addBtnBack: () => {
        const subMenu = Menu.navElt.querySelectorAll('.sub-menu');
        subMenu.forEach((elt) => {
            const newEl = document.createElement("li");
            newEl.innerHTML = `<button class="btn-back"><i class="icon-arrow"></i><span class="simpleLink">${elt.closest('li').querySelector('a').innerHTML}</span></button>`;
            elt.prepend(newEl);
        });

        Menu.navElt.querySelectorAll('.btn-back').forEach((elt) => {
            elt.addEventListener('click', (e) => {
                e.preventDefault();
                Menu.resetMenuNav();
            });
        })
    },
    resetMenuNav: () => {
        if(Menu.navElt.querySelector('li.hover--')) {
            Menu.navElt.querySelector('li.hover--').classList.remove('hover--');
        }
        Menu.navElt.classList.remove('hasHover--');
    }
};

export default Menu;