'use strict'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
const TabModuleAnimations =  {
    init: function(){
        this.zIndex = 0;
        if (document.getElementById("tabModule")) {
            this.activeItemIndex = 0;
            this.activeItemOnHover();
            this.scrollTriggerSection();
            this.swipeItemMobile();
        }
    },
    scrollTriggerSection : function() {
        const blockModule = document.getElementById('tabModule');
        if (blockModule) {
            const instructionText = blockModule.querySelector('.block-tabModule__instruction-desktop');
            gsap.registerPlugin(ScrollTrigger);
            gsap.to(blockModule, {
                scrollTrigger: {
                    trigger: blockModule,
                    start: "top center",
                    onEnter: (self) => {
                        self.trigger.classList.add('visible')
                    },
                    onLeaveBack: (self) => {
                        self.trigger.classList.remove('visible');
                    }
                }
            })
            let mm = gsap.matchMedia();
            mm.add("(min-width: 991px)", () => {
                gsap.fromTo(
                    instructionText,
                    { y: -50 }, // Valeur de départ de la translation verticale
                    {
                      y: 0, // Valeur d'arrivée de la translation verticale
                      scrollTrigger: {
                        trigger: blockModule,
                        start: "top 0",
                        end: "+=1200",
                        pin: true,
                        scrub: 0.8,
                      }
                    }
                  );
            })
        }
    },
    swipeItemMobile: function () {
        var tabModule = document.getElementById("tabModule");
        if (tabModule && window.innerWidth < 991) {
            const menuItems = tabModule.querySelectorAll('.js-hover-content');
            const images = tabModule.querySelectorAll('.clipped-img');
            const descriptions = tabModule.querySelectorAll('.js-description-tabModule');
            var startX = 0;
            var startY = 0;
    
            const handleTouchStart = (event) => {
                startX = event.touches[0].clientX;
                startY = event.touches[0].clientY;
            }
    
            const handleTouchEnd = (event) => {
                var endX = event.changedTouches[0].clientX;
                var endY = event.changedTouches[0].clientY;
                var deltaX = endX - startX;
                var deltaY = endY - startY;
                if (Math.abs(deltaX) > Math.abs(deltaY)) {
                    if (deltaX > 0) {
                        // swipe prev
                        if (this.activeItemIndex <= 0) {
                            this.activeItemIndex = (images.length - 1) 
                        } else {
                            this.activeItemIndex --
                        }
                    } else {
                        // swipe next 
                        if (this.activeItemIndex >= (images.length - 1)) {
                            this.activeItemIndex = 0
                        } else {
                            this.activeItemIndex ++ 
                        }
                    }
                    this.addActiveClass(menuItems, 'active', this.activeItemIndex);
                    this.addClippedImg(images, this.activeItemIndex)
                    // this.addActiveClass(images, 'appear', this.activeItemIndex);
                    this.addActiveClass(descriptions, 'appear', this.activeItemIndex);
                }
                startX = 0;
                startY = 0;
            }
            tabModule.addEventListener('touchstart', handleTouchStart, { passive: true });
            tabModule.addEventListener('touchend', handleTouchEnd, { passive: true });
        }
    },

    activeItemOnHover: function() {
        const tabModules = document.querySelectorAll('.block-tabModule');
        if (tabModules.length > 0) {
            tabModules.forEach(tab => {
                const menuItems = tab.querySelectorAll('.js-hover-content');
                const images = tab.querySelectorAll('.clipped-img');
                const descriptions = tab.querySelectorAll('.js-description-tabModule');
                if (menuItems.length > 0) {
                    menuItems.forEach((item, i) => {
                        // On bind le hover de chaque element qui a la classe js-hover-content 
                        images.forEach(img => {
                            img.style.transition ="none";
                        })
                        item.addEventListener("mouseover", (e) => {
                            this.activeItemIndex = i;
                            // On ajoute la class active sur l'element survolé et on le supprime sur les autres
                            this.addActiveClass(menuItems, 'active', i);
                            // On fait apparaitre la x eme image par rapport au x eme element survolé en lui ajoutant une class
                            this.addClippedImg(images, i)
                            // this.addActiveClass(images, 'appear', i);
                            // On fait apparaitre la x eme description par rapport au x eme element survolé en lui ajoutant une class
                            this.addActiveClass(descriptions, 'appear', i);
                        })
                    })
                }
            })
        }
    },
    addActiveClass: function(arraySelectors, className, itemArrayPosition) {
        if (arraySelectors.length > 0) {
            arraySelectors.forEach(selector => {
                if (selector.classList.contains(className)) {
                    selector.classList.remove(className)
                }
                if (arraySelectors[itemArrayPosition]) {
                    arraySelectors[itemArrayPosition].classList.add(className)
                }
            })
        }
    }, 
    addClippedImg: function(arrayImg, i) {
        this.zIndex ++
        arrayImg[i].angle = arrayImg[i].dataset.angle ? arrayImg[i].dataset.angle * 2 * Math.PI / 360 : 25 * 2 * Math.PI / 360;
        arrayImg[i].reverse = arrayImg[i].dataset.reverse ? arrayImg[i].dataset.reverse : false;

        arrayImg[i].addEventListener("transitionend", () => arrayImg[i].style.transition = "none")

        let { width, height } = arrayImg[i].getBoundingClientRect();
        let heightForTan = Math.tan(arrayImg[i].angle) * width;

        arrayImg[i].style.clipPath = `polygon(
            0px ${height + heightForTan}px,
            ${width}px ${height + heightForTan}px,
            ${width}px ${arrayImg[i].reverse ? height : height + heightForTan}px,
            0px ${arrayImg[i].reverse ? height + heightForTan : height}px
        )`;
        
        setTimeout(() => {
            arrayImg[i].style.transition = "clip-path 0.75s ease";
            arrayImg[i].style.zIndex = this.zIndex;
            arrayImg[i].style.clipPath = `polygon(
                0 ${height + heightForTan}px,
                ${width}px ${height + heightForTan}px,
                ${width}px ${arrayImg[i].reverse ? -heightForTan : 0}px,
                0 ${arrayImg[i].reverse ? 0 : -heightForTan}px
            )`;
        }, 10)
    }
}

export default TabModuleAnimations;