'use strict'

const Anchors =  {
    init: function(){
        if (document.querySelector('.js-anchor') && innerWidth > 991) {
          this.createAnchorLinks();
        }
    },
    
    createAnchorLinks: function() {
        const anchors = document.querySelectorAll('.js-anchor');

        const observerOptions = {
          root: null,
          rootMargin: "0px",
          threshold: 0.5
        };
      
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const anchorsListItems = document.querySelectorAll('[data-anchor]');
              anchorsListItems.forEach(item => {
                if (item.getAttribute('data-anchor') === entry.target.id) {
                  item.classList.add('active')
                } else {
                  item.classList.remove('active')
                }
              })
            }
          });
        }, observerOptions);

        const list = document.querySelector('.js-anchors-menu ul'); // création de la liste <ul>
        if (anchors.length > 0 && list) {
          anchors.forEach(anchor => {
            const id = anchor.getAttribute('id');
            if (id) {
              const listItem = document.createElement('li'); // création d'un élément <li>
              const link = document.createElement('a'); // création d'un lien <a>
              listItem.setAttribute('data-anchor', id);
              link.href = `#${id}`; // ajout de l'ancre
              listItem.appendChild(link); // ajout du lien dans l'élément <li>
              list.appendChild(listItem); // ajout de l'élément <li> dans la liste <ul>
            }
            anchors.forEach((anchor) => {
              observer.observe(anchor);
            });
            document.querySelector('.js-anchors-menu').style.opacity = 1;
          });
        }
        
    },

}

export default Anchors;