'use strict'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const OurWorkAnimations =  {
    init: function(){
        if (document.querySelector('.block-ourWork')) {
            this.verticalScroll();
            this.scrollTriggerOurWork();
            this.smartCursorPosition();
       }
    },

    smartCursorPosition: function() {
        const ourWorkDiv = document.getElementById('ourWork');
        if (window.innerWidth > 991 && ourWorkDiv) {
            const ourWorkItems = ourWorkDiv.querySelectorAll('.work__img');
            ourWorkItems.forEach(item => {
                const cursor = item.querySelector('.smart-cursor');
                item.addEventListener('mousemove', (e) => {
                    const rect = item.getBoundingClientRect();
                    const cursorWidth = cursor.offsetWidth;
                    const cursorHeight = cursor.offsetHeight;
                    let offsetX = e.clientX - rect.left - cursorWidth / 2;
                    let offsetY = e.clientY - rect.top - cursorHeight / 2;
                    cursor.classList.add('appear')
            
                    cursor.style.left = offsetX + 'px';
                    cursor.style.top = offsetY + 'px';

                    const keyframes = {
                        left: `${offsetX}px`,
                        top: `${offsetY}px`,
                      };
                      cursor?.animate(keyframes, {
                        duration: 1000,
                        fill: 'forwards'
                      });
                });
            
                item.addEventListener('mouseleave', (e) => {
                    cursor.classList.remove('appear')
                });
            }) 

        }

    },

    verticalScroll: function() {
        gsap.registerPlugin(ScrollTrigger);
        const blocksOurWork = document.querySelectorAll('.block-ourWork');

        if (blocksOurWork.length > 0) {
            blocksOurWork.forEach(block => {
                const ourWorkList = block.querySelector('.block-ourWork__list');
                const ourWorkItems = block.querySelectorAll('.block-ourWork__list-item');
                const workTitle = block.querySelectorAll('.block-ourWork__content');
                const spaceTop = 200;
                if (ourWorkList && ourWorkItems.length > 0) {
                    // Ajoute de la margin top progressivement sur les elements 
                    ourWorkItems.forEach((item, i) => {
                        item.style.marginTop = `${spaceTop * i}px`
                    })
        
                    // Scroll horizontal desktop
                    let mm = gsap.matchMedia();
                    mm.add("(min-width: 991px)", () => {
                        ourWorkList.style.width = `${ourWorkItems.length * 100}%`;
                        const diagonalScroll = gsap.timeline({
                            scrollTrigger: {
                                trigger: block,
                                start: "top 0",
                                pin: true,
                                end: "bottom -150%",
                                scrub: 0.8,
                                onUpdate: ()=> {
                                    if (parseFloat(document.querySelector('#dare').style.top) < 50) {
                                        document.querySelector('#dare').classList.add('visible')
                                        document.querySelector('.js-anchors-menu').classList.add('dark-menu')
                                    } else {
                                        document.querySelector('#dare').classList.remove('visible');
                                        document.querySelector('.js-anchors-menu').classList.remove('dark-menu')
                                    }
                                },
                                onLeave: ()=> {
                                    document.querySelector('.block-dare__img img').style.transform = 'translateY(0px)'
                                    document.querySelector('.block-dare__img img').style.transition = 'transform 0.6s ease-out'
                                },
                                onEnterBack: ()=> {
                                    document.querySelector('.block-dare__img img').style.transform = 'translateY(170px)'
                                    document.querySelector('.block-dare__img img').style.transition = 'transform 0.3s linear'
                                }
                            }
                        });
    
                        // on ajoute les animations à la timeline
                        diagonalScroll.to(ourWorkItems, {xPercent: -100 * (ourWorkItems.length - 1.5),y: -spaceTop * (ourWorkItems.length - 1),ease: "none",duration: 4})
                        .to(workTitle, {transform: "translateY(-200px)",duration: 1},"=")
                        // .to("#dare", {top: '0%', paddingBottom: '170px', duration: 3});
                       
                    })
                    // Fait apparaitre chaque element sur mobile en slide in depuis la droite 
                    mm.add("(max-width: 991px)", () => {
                        setTimeout(() => {
                            ScrollTrigger.refresh();
                        }, 1000);
                        ourWorkItems.forEach((item) => {
                            gsap.fromTo(
                                item,
                                { x: "100%" }, // position initiale en dehors de l'écran à droite
                                {
                                    x: "0%", // position finale à sa position par défaut
                                    duration: 0.4, // durée de l'animation
                                    ease: "power2.out", // ease-out
                                    scrollTrigger: {
                                        trigger: item,
                                        start: "center bottom", // déclenche l'animation lorsque le bas de l'élément atteint le centre de la fenêtre
                                        toggleActions: "play none none none", // contrôle le comportement de l'animation lors de l'entrée et de la sortie de la vue
                                    }
                                }
                            );
                        });
                    });
                }
            })
        }
    }, 
    scrollTriggerOurWork: function() {
        // Ajoute la class visible lorsque le block our Work est dans la vue 
        gsap.to('#ourWork', {
            scrollTrigger: {
                trigger: '#ourWork',
                start: "top center",
                onEnter: (self) => {
                    self.trigger.classList.add('visible')
                },
                onLeaveBack: (self) => {
                    self.trigger.classList.remove('visible');
                }
            }
        })
    }
}

export default OurWorkAnimations;