'use strict';
// import customSelect from 'custom-select';
import Redirection from '../components/redirection'
import RiseUpDecal from './riseupDecal'


const Press = {
    fullUrl : data_api['url_press_releases'],
    filters: new Array(),
    resultsAll: new Array,
    resultsfilters: new Array,
    resultsElt:null,
    nbResultsElt:null,
    formElt:null,
    filterElt: null,
    step: 6,
    currentStep:0,
    nameBtn: '',
    imgDefault: '',
	init: function(){
        const pressElt = document.querySelector('.c-press');
        if(pressElt) {
            Press.nameBtn = pressElt.querySelector('.c-press__results').dataset.btnname;
            Press.imgDefault = pressElt.querySelector('.c-press__results').dataset.imgdefault;
            Press.resultsElt = pressElt.querySelector('.c-press__content');
            Press.nbResultsElt = pressElt.querySelector('.c-press__nbresults');
            Press.filterElt = pressElt.querySelector('.c-press__filter--list');
            Press.formElt = pressElt.querySelector('.c-press__form');
            Press.eventsLoad();
            Press.loadJson(); //chargement du json
        }
    },
    eventsLoad:function() {
        Press.formElt.addEventListener('submit', (e) => {
            e.preventDefault();
            Press.resultsElt.innerHTML='';
            Press.resultsElt.classList.remove('show--');
            document.querySelector('.c-loader').classList.remove('hide--');
            Press.nbResultsElt.classList.remove('show--');
            if(Press.filterElt.querySelector('.c-select')) {
                Press.filterElt.querySelector('.c-select').remove();
            }
            Press.loadJson(Press.formElt.querySelector('input:not([type="submit"])').value);
        })
    },
    events: function() {
        Press.filterElt.querySelectorAll('select').forEach((select) => {
            select.addEventListener('change', function(e) {
                Press.resultsElt.innerHTML='';
                Press.resultsElt.classList.remove('show--');
                document.querySelector('.c-loader').classList.remove('hide--');
                Press.nbResultsElt.classList.remove('show--');
                Press.resultsfilters=Press.resultsAll.filter(Press.applyFilter);
                Press.currentStep=0;
                window.scrollTo({
                    behavior: 'smooth',
                    top: 0,
                 });
                Press.nbResults(Press.resultsfilters.length);
                Press.showResults(Press.resultsfilters)
            });
        });

        window.addEventListener('scroll', function(e) {
            if(document.querySelector('.c-loader:not(.hide--)')) {
                var positionLoader = document.querySelector('.c-loader').getBoundingClientRect();
                if( positionLoader.top<= window.innerHeight) {
                    Press.showResults(Press.resultsfilters);
                }
            }
        });

        document.querySelector('.c-loader').addEventListener('click', function(e) {
            e.preventDefault();
            Press.showResults(Press.resultsfilters);
        })
    },
    applyFilter:function(obj) {
        var status=true;
        document.querySelectorAll('.c-select select').forEach(function(item) {
            //filtre par date
            if(item.name=="filteryear" && item.value!="all" && status==true) {
                status=false;
                if(item.value==obj.year) {
                    status=true;
                }
            }
        });
        return status;
    },
    loadJson: function(search='') {
        let request = new XMLHttpRequest();
		request.open("GET", Press.fullUrl+`&title=${search}`);
		request.onreadystatechange = function () {
			if (request.readyState == 4 && request.status == 200) {
                var data = JSON.parse(request.responseText);
                Press.filter = data.filters;
                Press.resultsAll = data.content;
                Press.resultsfilters = data.content;
                Press.currentStep = 0;
                Press.showFilter(); //affiche les filtres
                Press.nbResults(data.content.length);
                Press.showResults(data.content); //affiche tous les résultats
            }
		};
		request.send();
    },
    showFilter: function() {
        if(Press.filter)
        {
            for(var key in Press.filter) {
                var filtre = Press.filter[key]
                if(filtre) {
                    var items = "";
                    var count=0;
                    for(var cle in filtre) {
                        items+='<option value="'+cle+'">'+filtre[cle]+'</option>';
                        count++;
                    }
                    if(count>1) {
                        Press.filterElt.insertAdjacentHTML('beforeend', `<div class="c-select js-elt" data-cursor-hover><select name="filter${key}">${items}</select></div>`);
                    }
                }
            }
            
            var timer=setTimeout(function() {
                Press.prettyShowFilter(Press.filterElt);
                Press.events();
                clearTimeout(timer);
            }, 200)
            
        }
    },
    showResults: function(resultsList) {
        var items = "";
        var endSearch=resultsList.length;
        if(Press.currentStep + Press.step <= endSearch) {
            endSearch = Press.currentStep + Press.step;
        }
        for(var i= Press.currentStep; i < endSearch; i++) {
            
            items+=`<a class="c-press__item js-elt" href="${resultsList[i].url}">
                <div class="c-press__item--img">
                    <img src="${resultsList[i].image.src!='' ? resultsList[i].image.src : Press.imgDefault}" alt="${resultsList[i].image.title!='' ? resultsList[i].image.title : resultsList[i].title}" loading="lazy" />
                </div>
                <div class="c-press__item--content">
                    <p class="c-press__date"><span>${resultsList[i].date}</span></p>
                    <div class="c-press__description">
                        <h2 class="h4">${resultsList[i].title}</h2>
                        <div class="btn-simple"><span>${Press.nameBtn}</span><i class="icon-arrow"></i></div>
                    </div>
                </div>
            </a>`;
        }

        Press.currentStep +=Press.step;
        Press.resultsElt.insertAdjacentHTML('beforeend', items);
        
        Press.resultsElt.classList.add('show--');

        if(endSearch >= resultsList.length) {
            document.querySelector('.c-loader').classList.add('hide--');
        }
        

        Redirection.init();
        RiseUpDecal.init();
    },
    nbResults:function(number) {
        Press.nbResultsElt.querySelector('span').innerHTML = number;
        Press.nbResultsElt.classList.add('show--');
    },
    prettyShowFilter:function(filterElt) {
        filterElt.parentNode.classList.add('show--');
        var timer = new Array();
        Press.filterElt.querySelectorAll('.js-elt').forEach(function(elt, index) {
            var delay=100*index*1.5;
            timer[index] = setTimeout(function() {
                elt.style.transform = 'translateY(0)';
                elt.style.opacity= '1';
            }, delay);
        });
    }
};

export default Press;