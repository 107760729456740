'use strict'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const AnimGsap =  {
    init: function(){
        gsap.registerPlugin(ScrollTrigger);

        const sectionsSticky = document.querySelectorAll('.js-sticky');
        this.stickyOnscroll(sectionsSticky)
    },

    stickyOnscroll: function(ArraySelectors) {
        // Ajouter la class .js-sticky a la section pour qu'elle reste bloqué au top de l'ecran sur desktop et au bottom sur mobile
        if (ArraySelectors.length > 0) {
            ArraySelectors.forEach(el => {
                ScrollTrigger.create({
                    trigger: el,
                    start: () => el.offsetHeight < window.innerHeight ? "top 100px" : "bottom bottom",
                    pin: true, 
                    pinSpacing: false 
                });
            })
        }
    },
}

export default AnimGsap;