'use strict'
import 'intersection-observer';
const riseUp = {
  elements: document.querySelectorAll('.js-data-rise:not(.visible--)'),
  options: {threshold: [0.2, 0.55, 0.80, 1]},
  observer:'',

  init: function(){
    if(document.querySelectorAll('.js-data-rise:not(.visible--)')) {
      riseUp.observer = new IntersectionObserver(riseUp.action, riseUp.options);
      for (let element of this.elements) {
        this.prepare(element)
        riseUp.observer.observe(element);
      }
    }
  },

  prepare: function(item){
    if(document.body.clientWidth<=768) {
      item.style.transform = 'translateY(5rem)'
    }
    else {
      item.style.transform = 'translateY(12rem)'
    }
    item.style.opacity= '0'
    item.style.transition = 'all 0.5s ease-out'
  },

  action: function(items){
    items.forEach((item) => {
      if (item.intersectionRatio > 0.1){
        item.target.style.transform = 'translateY(0)'
        item.target.style.opacity= '1'
        item.target.classList.add('visible--');
        riseUp.observer.unobserve(item.target);
      }
    })
  }
}

export default riseUp
