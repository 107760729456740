'use strict'

const Counter =  {
    init: function(){
        this.initCounterOnScroll();
    },

    initCounterOnScroll: function() {

        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        };

        const observer = new IntersectionObserver((entries) => {
            
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const anchorsListItems = document.querySelectorAll('.f-keyFigures_item');
                anchorsListItems.forEach(item => {
                    if (!item.classList.contains('js-played')) {
                        this.incrementCounter(item)
                        item.classList.add('js-played');
                    }
                })
            }
        });
        }, observerOptions);

        const keyfigureBlocks = document.querySelectorAll('.f-keyFigures_item');
        if (keyfigureBlocks.length > 0) {
            keyfigureBlocks.forEach(block => {
                observer.observe(block);
            })
        }
    },
    incrementCounter: function(keyFigureBlock) {
        const keyFigures = keyFigureBlock.querySelectorAll('.js-number-counter');
        if (keyFigures.length > 0) {
            keyFigures.forEach((element) => {
                const countTo = parseFloat(element.getAttribute('data-count'), 10);
                let countNum = parseFloat(element.innerText, 10);
                const duration = 1500; // durée totale de l'animation en millisecondes
                const slices = 50; // nombre de tranches pour l'animation
                const sliceDuration = duration / slices; // durée de chaque tranche en millisecondes
                const sliceIncrement = (countTo - countNum) / slices; // incrémentation de chaque tranche
                let currentSlice = 0;
                
                const interval = setInterval(() => {
                    if (currentSlice < slices) {
                        countNum += sliceIncrement;
                        element.innerText = this.formatNumber(Math.floor(countNum));
                        currentSlice++;
                    } else {
                        clearInterval(interval);
                        element.innerText = this.formatNumber(countTo);
                    }
                }, sliceDuration);
            });
        }
    },
    formatNumber: function(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
}

export default Counter;