'use strict'

const GlobalModal = {
    el: null,
    modal: null,
    closeBtn: null,
    init: function () {
        GlobalModal.el = document.querySelector('.js-global-modal')
        GlobalModal.modal = GlobalModal.el?.querySelector('.js-modal__modal')
        GlobalModal.closeBtn = GlobalModal.el?.querySelector('.js-modal__modal--close-btn')

        if (GlobalModal.el && GlobalModal.modal && GlobalModal.closeBtn) {
            GlobalModal.show()
        }
    },
    show: function () {
        let modalId = GlobalModal.el.getAttribute('data-id');
        let visibleOnce = GlobalModal.el.getAttribute('data-visible-once') === "true";
        let shouldShow = true;

        if (visibleOnce) {
            // Tableau des ID des popins vues
            const shownModals = JSON.parse(window.localStorage.getItem('globalModals')) || [];

            // Si ID de la popin est dans le tableau on affiche pas la popin
            if (shownModals.includes(modalId)) {
                shouldShow = false;
            }
        }

        if (shouldShow) {
            GlobalModal.modal.classList.add('open');
            GlobalModal.closeBtn.addEventListener('click', GlobalModal.closeModal);

            // Ajoute l'ID de la popin dans le local storage si data-visible-once est a "true" 
            if (visibleOnce) {
                const updatedShownModals = JSON.parse(window.localStorage.getItem('globalModals')) || [];
                updatedShownModals.push(modalId);
                window.localStorage.setItem('globalModals', JSON.stringify(updatedShownModals));
            }
        }
    },
    closeModal: function () {
        GlobalModal.modal.classList.remove('open')
    }
}

export default GlobalModal;