'use strict'

const ColorThemeDetector =  {
    init: function(){
        this.detectColorTheme();
    },

    detectColorTheme: function() {
        // Ajouter data-theme-color="white-theme" aux section qui doivent passer en blanc lorsqu'elles sont visible a 50%
        // mettre par defaut data-theme-color="dark-theme" sur toutes les autres sections pour refaire le switch si nn contribué
        const elements = document.querySelectorAll('[data-theme-color]');
        const options = {
            root: null, // Utilise la fenêtre comme racine
            rootMargin: '-50% 0% -50% 0%', // Détecte lorsque le bas ou le haut du block est au milieu de la fenetre
            threshold: 0 // Valeur par default 
        };

        // Crée une nouvelle instance de l'observateur d'intersection
        if (elements.length > 0) {
          const observer = new IntersectionObserver(function(entries, observer) {
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  const targetElement = entry.target;
                  const themeColor = targetElement.getAttribute('data-theme-color');
                  if (themeColor === 'white-theme') {
                    document.querySelector('body').classList.add('white-theme');
                  } else {
                    document.querySelector('body').classList.remove('white-theme');
                  }
                }
              });
            }, options);
  
          // Observateur d'intersection pour chaque élément
          elements.forEach(element => {
              observer.observe(element);
          });

        }
    }
}

export default ColorThemeDetector;