'use strict'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const HeroAnimations =  {
    init: function(){
        if(document.querySelector('#dare')) {
            this.scrollTriggerDare();
        }
    },

    scrollTriggerDare: function() {
        // Ajoute la class visible lorsque le block dare est dans la vue 
        // let mm = gsap.matchMedia();
        // mm.add("(max-width: 991px)", () => {
            gsap.to('#dare', {
                scrollTrigger: {
                    trigger: '#dare',
                    start: "top center",
                    onEnter: (self) => {
                        self.trigger.classList.add('visible')
                        document.querySelector('.js-anchors-menu').classList.add('dark-menu')
                    },
                    onLeaveBack: (self) => {
                        self.trigger.classList.remove('visible');
                        document.querySelector('.js-anchors-menu').classList.remove('dark-menu')
                    }
                }
            })
        // })
          
    },
}

export default HeroAnimations;