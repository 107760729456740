'use strict'
import 'intersection-observer';
const riseUpDecal = {
  elements: '',
  options: {threshold: [0.2, 0.55, 0.80, 1]},
  observer:'',

  init: function(){
    this.elements = document.querySelectorAll('.js-data-rise-decal .js-elt:not(.visible--):not(.hide--)');
    if(document.querySelectorAll('.js-data-rise-decal .js-elt:not(.visible--):not(.hide--)')) {
      riseUpDecal.observer = new IntersectionObserver(riseUpDecal.action, riseUpDecal.options);
      for (let element of this.elements) {
        this.prepare(element)
        riseUpDecal.observer.observe(element);
      }
    }
  },

  prepare: function(item){
    if(document.body.clientWidth<=768) {
      item.style.transform = 'translateY(5rem)'
    }
    else {
      item.style.transform = 'translateY(12rem)'
    }
    item.style.opacity= '0'
    item.style.transition = 'all 0.5s ease-out'
  },

  action: function(items){
    var timer = new Array();
    items.forEach((item, index) => {
      if (item.intersectionRatio > 0.1){
        var delay=100*index*1.2;
        timer[index] = setTimeout(function() {
          item.target.style.transform = 'translateY(0)'
          item.target.style.opacity= '1'
          item.target.classList.add("visible--");
          clearTimeout(timer[index]);
        }, delay);
        riseUpDecal.observer.unobserve(item.target);
      }
    })
  }
}

export default riseUpDecal
