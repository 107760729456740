'use strict'

const Gallery =  {
    el: null,
    init: function(){
        Gallery.el = document.querySelector('.c-gallery');

        if(Gallery.el){
            const items = Gallery.el.querySelectorAll('li');

            items.forEach( item => {
                // Smart cursor
                const cursor = item.querySelector('.smart-cursor');
                Gallery.smartCursorPosition(item, cursor);
            })
        }
    },

    smartCursorPosition: function(detector, cursor) {
        if(window.innerWidth > 991) {
            detector.addEventListener('mousemove', (e) => {
                const rect = detector.getBoundingClientRect();
                const cursorWidth = cursor.offsetWidth;
                const cursorHeight = cursor.offsetHeight;
                const cursorLeft = e.clientX - rect.left - cursorWidth / 2;
                const cursorTop = e.clientY - rect.top - cursorHeight / 2;

                cursor.style.left = cursorLeft + 'px';
                cursor.style.top = cursorTop + 'px';
                const keyframes = {
                    left: `${cursorLeft}px`,
                    top: `${cursorTop}px`,
                  };
                  cursor?.animate(keyframes, {
                    duration: 1000,
                    fill: 'forwards'
                  });
            });
        }
    }
}

export default Gallery;