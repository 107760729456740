'use strict'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import lottie from 'lottie-web';
gsap.registerPlugin(ScrollTrigger);

const HeroAnimations = {
    init: function () {
        window.addEventListener("load", () => {
            if (document.querySelector('#hero')) {
                document.querySelector('#hero').classList.add('appear');
            }
            this.logoAnimated();
        }, false);
        this.animationsOnScroll();
        this.readMoreAnimations();

        HeroAnimations.videoResponsive();
        window.addEventListener('resize', () => {
            HeroAnimations.videoResponsive();
        })
    },

    animationsOnScroll: function () {
        const section = document.querySelector('.block-hero');
        const logo = document.querySelector('.logo-header');
        // on définit les paramètres de l'animation et va se jouer que sur desktop
        if (section) {
            const hoverBtn = section.querySelector('.js-hover-btnVideo');
            const video = section.querySelector('.block-hero__video');
            const content = section.querySelector('.block-hero__content');
            const line = section.querySelector('.block-hero__line');
            let mm = gsap.matchMedia();
            mm.add("(min-width: 991px)", () => {
                const increaseVideo = gsap.timeline({
                    scrollTrigger: {
                        trigger: section,
                        start: 'center center',
                        pin: true,
                        scrub: true,
                    }
                });
                // on ajoute les animations à la timeline
                increaseVideo.to(content, { top: '-100%', duration: 2 })
                    .to(line, { top: '-100%', duration: 2 }, "<")
                    .to(video, { width: '100%', height: '100%', duration: 2 }, 0)
                    .to(logo, { top: 0, duration: 1 })
                    .to(hoverBtn, { right: 0, duration: 1 }, "<")

                const decreaseVideo = gsap.timeline({
                    scrollTrigger: {
                        trigger: section,
                        start: 'center 20%',
                        pin: false,
                        scrub: true,
                    }
                });
                decreaseVideo.to(video, { transform: 'scale(0.5)', duration: 2 })
            })
        }
    },
    readMoreAnimations: function () {
        const videoDescription = document.querySelector('.block-hero__video__description');
        if (videoDescription) {
            const readMoreBtn = videoDescription.querySelector('.read-more');
            const hoverBtn = document.querySelector('.js-hover-btnVideo');
            readMoreBtn.addEventListener('click', () => {
                videoDescription.classList.remove('hovered');
                hoverBtn.classList.remove('hide');
                if (window.innerWidth < 991) {
                    videoDescription.classList.toggle('open');
                }
            })
            if (hoverBtn || window.innerWidth > 991) {
                hoverBtn.addEventListener('mouseenter', () => {
                    videoDescription.classList.add('hovered');
                    hoverBtn.classList.add('hide')
                })
            }
        }
    },
    logoAnimated: function () {
        const logoContainer = document.querySelector('.hero-logo');
        if (logoContainer) {
            const animation = lottie.loadAnimation({
                container: logoContainer,
                path: './havas-content/themes/havas-group/dist/assets/images/logo_red-animated.json',
                renderer: 'svg',
                loop: false,
                autoplay: false,
            });

            // Événement appelé lorsque l'animation est chargée
            animation.addEventListener('DOMLoaded', () => {
                animation.setSpeed(0.8);
                animation.play();
            });

            // Événement appelé lorsque l'animation atteint sa dernière frame
            animation.addEventListener('complete', () => {
                animation.pause();
            });
        }
    },
    videoResponsive: function () {
        const video = document.querySelector('.block-hero .block-hero__video video');
        if (video) {
            const desktop = video.dataset.urldesktop;
            const mobile = video.dataset.urlmobile;
            if (window.matchMedia("(max-width: 991px)").matches) {
                if (mobile != "" && mobile != video.querySelector('source').getAttribute('src')) {
                    video.querySelector('source').setAttribute('src', mobile);
                    video.load();
                    video.play();
                }
            }
            else {
                if (desktop != video.querySelector('source').getAttribute('src')) {
                    video.querySelector('source').setAttribute('src', desktop);
                    video.load();
                    video.play();
                }
            }
        }
    }
}

export default HeroAnimations;