'use strict'
const axios = require("axios");
import 'intersection-observer';
import RiseUpDecal from "./riseupDecal";

const FindUs =  {
  btnFilters: null,
  apiAgencies: data_api['url_agencies'], //recuperation url agencies api
  apiCountries: data_api['url_countries'], //recuperation url country api
  apiKey: data_api['agencies_api_partner_key'], //securitykey api
  listMenu:null,
  eltMenu:null,
  eltPre:null,
  eltPopin:null,
  eltContent:null,
  eltResultsContent:null,
  eltAllRegion:null,
  eltAllCountries:null,
  eltFormCountry:null,
  eltCountriesResult:null,
  eltAgenciesResult:null,
  eltOverlay:null,
  serviceSelected:null,
  regionSelected:'',//liste des regions selectionnées validés (filtre)
  countriesSelected:[],//liste des pays selectionnées validés (filtre)
  regionSelectedCurrent:'',//liste des regions selectionnées provisoire (filtre)
  countriesSelectedCurrent:[],//liste des pays selectionnées provisoire (filtre)
  nbFilters:0,
  nbResults:0,
  resultsAgencies:[],
  nbResultsPerPage:20,
  currentIndex:0,
  init: () => {
      const eltFindUs = document.querySelector('.c-findus')
      if (eltFindUs) {
        FindUs.eltMenu = eltFindUs.querySelector('.c-findus__menu');
        FindUs.eltPre = eltFindUs.querySelector('.c-findus__pre');
        FindUs.eltPopin = document.querySelector('.c-findus__popin');
        FindUs.eltContent = eltFindUs.querySelector('.c-findus__container');
        FindUs.eltResultsContent = FindUs.eltContent.querySelector('.c-findus__results--content')
        FindUs.btnFilters = document.getElementById('btn-filter');
        FindUs.eltFormCountry = document.querySelector('.c-findus__form');
        FindUs.eltOverlay = document.querySelector('.c-findus__overlay');
        FindUs.eltCountriesResult = FindUs.eltPopin.querySelector('.c-findus__form--results');
        FindUs.eltAgenciesResult = eltFindUs.querySelector('.c-findus__agencies--list');
        FindUs.getParams();
        FindUs.event();
        FindUs.loadCountries();
        if (window.matchMedia("(max-width: 991px)").matches) {
          FindUs.eltResultsContent.insertBefore(document.getElementById('btnBackPre'), FindUs.eltResultsContent.querySelector('.c-findus__intro'));
        }
      }
  },
  getParams: () => {
    let params = (new URL(document.location)).searchParams;
    if(params.get('service')) { //s'il y a une pagination
      FindUs.serviceSelected = params.get('service');
      FindUs.showContent(params.get('service'));
    }
    else {
      FindUs.showPre();
    }
  },
  updateParams:function(params, value) {
    var url = new URL(window.location);
    url.searchParams.set(params, value);
    url.search = decodeURIComponent(url.search)
    window.history.pushState({}, '', url);
  },
  removeParams:function(params) {
      var url = new URL(window.location);
      url.searchParams.delete(params);
      window.history.pushState({}, '', url);
  },
  event: () => {
    //Au clic des services sur la prehome
    const links = FindUs.eltPre.querySelectorAll('li button');
    links.forEach((link) => {
      link.addEventListener('click', (e)=> {
        e.preventDefault();
        FindUs.serviceSelected = link.dataset.service;
        FindUs.showContent(link.dataset.service);
        FindUs.resultsAgencies = []
        FindUs.updateParams('service', link.dataset.service);
      })
    });

    //Au clic des services ds le menu de gauche
    const linksMenu = FindUs.eltMenu.querySelectorAll('li button:not(#btnBackPre)');
    linksMenu.forEach((link) => {
      link.addEventListener('click', (e)=> {
        e.preventDefault();
        FindUs.serviceSelected = e.target.dataset.service;
        FindUs.showContent(e.target.dataset.service);
        FindUs.resultsAgencies = []
        FindUs.closePopin();
        FindUs.clearAll();
        FindUs.updateParams('service', link.dataset.service);
      })
    });

    //Au clic sur le menu retour
    if(document.getElementById('btnBackPre')) {
      document.getElementById('btnBackPre').addEventListener('click', (e)=> {
        e.preventDefault();
        FindUs.showPre();
        FindUs.removeParams('service');
        FindUs.closePopin();
        FindUs.clearAll();
      });
    }
    //Au click sur le bouton filtre par country
    document.getElementById('btn-filter').addEventListener('click', (e)=> {
      FindUs.eltResultsContent.classList.add('hide--');
      FindUs.regionSelectedCurrent = FindUs.regionSelected;
      if(FindUs.countriesSelected.length > 0) {
        FindUs.countriesSelectedCurrent=[];
        FindUs.countriesSelected.forEach((elt) => {
          FindUs.countriesSelectedCurrent.push(elt)
        });
      }
      FindUs.getSelectedRegion();
      const timer = setTimeout(() => {
        FindUs.eltPopin.classList.add('show--');
        FindUs.eltContent.classList.remove('showContent--');
        FindUs.eltResultsContent.querySelector('.c-findus__agencies').classList.remove('show--');
        clearTimeout(timer);
      }, 500)
      
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = 'hidden'
    });

    //Au click sur le btn close popin
    FindUs.eltPopin.querySelector('.btn-close').addEventListener('click', (e) => {
      e.preventDefault();
      FindUs.regionSelectedCurrent = FindUs.regionSelected;
      FindUs.countriesSelectedCurrent=[];
      FindUs.countriesSelected.forEach((elt) => {
        FindUs.countriesSelectedCurrent.push(elt)
      })
      FindUs.countFiltersSelected();
      FindUs.showSelectedCountryList();
      FindUs.eltContent.classList.add('showContent--');
      FindUs.eltResultsContent.querySelector('.c-findus__agencies').classList.add('show--');
      FindUs.closePopin();
    })
    if(FindUs.eltFormCountry) {
      //Au focus ou remove sur le champs de recherche d'un pays
      FindUs.eltFormCountry.querySelector('input').addEventListener('focus', () => {
        FindUs.eltFormCountry.closest('.c-findus__country').classList.add('open--');
      })
      
      //Remove open au click ailleurs que sur la recherche de pays
      document.addEventListener("mouseup", function(e) {
        if (!FindUs.eltFormCountry.contains(e.target)) {
          FindUs.eltFormCountry.closest('.c-findus__country').classList.remove('open--');
          FindUs.eltCountriesResult.innerHTML ="";//reset du contenu de resultats
        }
      });


      FindUs.eltFormCountry.addEventListener('submit', (e) => {
        e.preventDefault();
      })

      //Modification du champs de recherche de pays
      var timer = null;
      FindUs.eltFormCountry.querySelector('input').addEventListener('keyup', (e) => {
        clearTimeout(timer);
        if(e.target.value.length >= 3) {
          timer = setTimeout(() => {
              FindUs.loadCountries(e.target.value);//appel de l'api Country
          }, 500);
        }
      });
    }
    //Au click sur le bouton reset de filtre
    document.getElementById('btn-clear').addEventListener('click', (e) => {
      e.preventDefault();
      FindUs.clearAll();
    });

    //Au click sur le bouton appliquer les filtres
    document.getElementById('btn-apply').addEventListener('click', (e) => {
      e.preventDefault();
      FindUs.countFiltersSelected();
      
      const list = FindUs.getlistCountries();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = 'auto'
      FindUs.regionSelected = FindUs.regionSelectedCurrent;
      FindUs.regionSelectedCurrent = '';
      FindUs.countriesSelected = [];
      FindUs.countriesSelectedCurrent.forEach((elt) => {
        FindUs.countriesSelected.push(elt);
      })
      FindUs.countriesSelectedCurrent = [];
      FindUs.loadAgencies(FindUs.serviceSelected, FindUs.regionSelected, list);
      FindUs.eltPopin.classList.remove('show--');
      FindUs.eltResultsContent.classList.remove('hide--')
    })
  },
  getlistCountries: () => {
    let list = '';
    if(FindUs.countriesSelectedCurrent.length > 0){
      FindUs.countriesSelectedCurrent.forEach((elt) => {
        if(list !='') {
          list +=','
        }
        list += elt.slug;
      })
    }
    return list;
  },
  loadAgencies: (service='', region='', country='', firstLoad=false) => {//appel de l'api Agencies
    FindUs.eltAgenciesResult.innerHTML="";//reset affichage contenu
    FindUs.eltResultsContent.querySelector('.c-findus__agencies').classList.remove('show--');
    FindUs.resultsAgencies = [];//reset du tableau de resultat
    FindUs.currentIndex = 0;//reset de l'indew de depart
    let url = FindUs.apiAgencies+'&expertise='+service;
    if(region!='') {
      url+='&region='+region
    }
    if(country!='') {
      url+='&country='+country;
    }
    axios({
        method: "get",
        url: url,
        headers: {'partner-key': FindUs.apiKey},
    }).then(function (response) {
      // console.log(response)
        const {data: {content, services}} = response;
        if (content && services) {
          //handle success
          FindUs.formatAgenciesList(content);
          if(firstLoad && services) {
            FindUs.serviceContent(services);
          }
          FindUs.eltContent.classList.add('showContent--');
        } else {
            //handle error
            console.log('err getAgencies', message);
        }
    }).catch(function (response) {
        //handle error
        console.log('err catch getAgencies', response);
    });
  },
  loadCountries: (search_country="") => {//appel de l'api countries
    axios({
      method: "get",
      url: FindUs.apiCountries+'&search_country='+search_country,
      headers: {'partner-key': FindUs.apiKey},
    }).then(function (response) {
      // console.log(response)
      //handle success
      if(!search_country) {
        const {data: {filters:{region, country}}} = response;
        if (region && country && !search_country) {
        
          FindUs.getAllFilters(region, country);
          // FindUs.showPre();
        }
      }
      else if(search_country!="") {
        const {data: {countries}} = response;
        FindUs.listSearchCountry(countries, search_country);
      } else {
          //handle error
          console.log('err getCountries', message);
      }
    }).catch(function (response) {
      //handle error
      console.log('err catch getCountries', response);
    });
  },
  serviceContent: (arrayService) => {
    arrayService = arrayService[Object.keys(arrayService)];
    let intro = '';
    const introElt = FindUs.eltResultsContent.querySelector('.c-findus__intro');
    if(introElt) {
      if(arrayService.name) {
        intro = `<h2 class="h3 title-- title-animated">${arrayService.name}</h2>`;
      }
      if(arrayService.desc) {
        intro += `<p>${arrayService.desc}</p>`;
      }
    }
    introElt.innerHTML = intro;

    //networks
    let networks = ''
    const networkElt = FindUs.eltResultsContent.querySelector('.c-findus__network');
    if(arrayService.networks.length > 0 && networkElt) {
      networks = `<h3 class="h4 title--">${networkElt.dataset.title}</h3>
      <div class="c-findus__network--list">`;
      arrayService.networks.forEach((elt) => {
        networks += `<a href="${elt.link_network.url}" target=="${elt.link_network.target}" class="c-findus__network--item">
              <h4 class="h3 title--">${elt.name_network}</h4>
              <p>${elt.desc_network}</p>
              <p class="link--"><button><i class="icon-link"></i><span>Visit</span></button></p>
          </a>`;
      });
      networks +=`   
      </div>`;
    }
    networkElt.innerHTML = networks;
  },
  showPre: () => {
    FindUs.eltContent.classList.remove('appear--');
    FindUs.eltContent.classList.remove('showContent--');
    document.body.classList.remove('white-theme');
    let timer = setTimeout(() => {
      FindUs.eltContent.classList.remove('show--');
      FindUs.eltPre.classList.add('showContent--');
      clearTimeout(timer);
    }, 1000);
  },
  showContent: (service) => {
    FindUs.eltPre.classList.remove('showContent--');
    FindUs.eltContent.classList.remove('showContent--');
    if(service) {
      FindUs.loadAgencies(service, '', '', true);
    
      if(FindUs.eltMenu.querySelector(`li.current--`)) {
        FindUs.eltMenu.querySelector(`li.current--`).classList.remove("current--");
      }
      FindUs.eltMenu.querySelector(`li button[data-service="${service}"`).closest('li').classList.add("current--");
    }
    
    let timer = setTimeout(() => {
      FindUs.eltContent.classList.add('show--');
      FindUs.eltContent.classList.add('appear--');
      document.body.classList.add('white-theme');
      clearTimeout(timer);
    }, 300)
  },
  eventLoadAgencies: () => {
    FindUs.eltResultsContent.querySelector('.c-findus__agencies .title-- .number').innerHTML = `(${FindUs.resultsAgencies.length})`;
    FindUs.eltResultsContent.querySelector('.c-findus__agencies').classList.add('show--');
    FindUs.showAgencyList();

    // Observe loadBtn
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    const observer = new IntersectionObserver((entries) => {
      // Callback to be fired
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          FindUs.showAgencyList();
        }
      });
    }, options);
  
    observer.observe(document.getElementById('btn-load'));
  },
  formatAgenciesList : (list) => { //format la liste des resultats agence en tableau
    FindUs.resultsAgencies = [];
    for (var cle in list) {
      FindUs.resultsAgencies.push({title:list[cle].title, website:list[cle].website, description:list[cle].description});
    }
    FindUs.eventLoadAgencies();
  },
  showAgencyList: () => { //affiche 
    let endIndex = FindUs.currentIndex + FindUs.nbResultsPerPage;
    if(endIndex > FindUs.resultsAgencies.length) {
      endIndex = FindUs.resultsAgencies.length;
    }
    
    for(var i= FindUs.currentIndex; i < endIndex; i++) {
      let html = '';
      if (window.matchMedia("(min-width: 991px)").matches) {
        html +=`<a href="${FindUs.resultsAgencies[i].website}" class="c-findus__agencies--item show--" target="_blank">`;
      }
      else {
        html +=`<div class="c-findus__agencies--item" data-link="${FindUs.resultsAgencies[i].website}">
          <button class="icon-close" onClick="this.closest('.c-findus__agencies--item').classList.toggle('open--')"></button>
        `;
      }
      html +=`
      <h4 class="h3 title--" onClick="this.closest('.c-findus__agencies--item').classList.toggle('open--')">${FindUs.resultsAgencies[i].title}</h4>
      <div class="intro--">`;
        if(FindUs.resultsAgencies[i].description) {
          html+=`<p>${FindUs.resultsAgencies[i].description}</p>`
        }
        if (window.matchMedia("(min-width: 991px)").matches) {
          html+= `<p class="link--"><button><i class="icon-link"></i><span>${FindUs.eltAgenciesResult.dataset.visit}</span></button></p>`;
        }
        else {
          html+= `<p class="link--"><a href="${FindUs.resultsAgencies[i].website}" target="_blank"><i class="icon-link"></i><span>${FindUs.eltAgenciesResult.dataset.visit}</span></a></p>`;
        }
      html+=`</div>`;
      if (window.matchMedia("(min-width: 991px)").matches) {
        html +=`</a>`;
      }
      else {
        html +=`</div>`;
      }
      FindUs.eltAgenciesResult.insertAdjacentHTML('beforeend', html);
    }
    
    let timer = setTimeout(function() {
      FindUs.eltAgenciesResult.querySelectorAll(`.c-findus__agencies--item:not(.show--)`).forEach((elt) => {
        elt.classList.add('show--')
      })
      clearTimeout(timer)
    }, 300)
    
    FindUs.currentIndex = endIndex;
  },
  getAllFilters: (region, countries) => { //recupération de tous les filtres
    FindUs.eltAllRegion = FindUs.eltPopin.querySelector('.c-findus__region--list');
    if(region && FindUs.eltAllRegion) {
      let html = "<ul>";
      for (var key in region) {
        html+=`<li><button data-region="${key}">${region[key]}</button></li>`;
      }
      html+='</ul>';
      FindUs.eltAllRegion.innerHTML = html;
    }
    
    FindUs.eltAllCountries = FindUs.eltPopin.querySelector('.c-findus__countries');
    if(countries && FindUs.eltAllCountries) {
      let html = "";
      for (var key in countries) {
        html += `
          <div class="c-findus__letter js-elt">
            <h4 class="h4">${key}</h4>
            <ul>`;
              countries[key].forEach((item) => {
                html += `<li><button data-country="${item['country-slug']}" data-region="${item['region-slug']}">${item['country-name']}</button></li>`;
              });
              html +=`
            </ul>
          </div>`;
      }
      FindUs.eltAllCountries.innerHTML = html;
      RiseUpDecal.init();
    }

    if(FindUs.eltAllRegion && FindUs.eltAllCountries) {
      FindUs.eventLoadFilters();
    }
  },
  eventLoadFilters: () => {//evenements sur les filtres
    //Au clic sur une région
    FindUs.eltAllRegion.querySelectorAll('li button').forEach((elt) => {
      elt.addEventListener('click', (e) => {
        FindUs.regionSelectedCurrent = e.target.getAttribute('data-region');
        if(FindUs.eltAllRegion.querySelector('li button.selected--')) {
          if(e.target === FindUs.eltAllRegion.querySelector('li button.selected--')) {
            FindUs.eltAllRegion.querySelector('li button.selected--').classList.remove('selected--');
            FindUs.regionSelectedCurrent = "";
          }
          else {
            FindUs.eltAllRegion.querySelector('li button.selected--').classList.remove('selected--');
            e.target.classList.add('selected--');
          }
        }
        else {
          e.target.classList.add('selected--');
          FindUs.regionSelectedCurrent = e.target.dataset.region;
        }
        FindUs.showCountriesByRegion();
      })
    })

    //Au clic sur un pays dans la liste totale
    FindUs.eltAllCountries.querySelectorAll('li button').forEach((elt) => {
      elt.addEventListener('click', (e) => {
        if(!e.target.classList.contains('selected--')) {
          e.target.classList.add('selected--');
          FindUs.countriesSelectedCurrent.push({slug:e.target.dataset.country, name:e.target.innerHTML});
          FindUs.showSelectedCountryList();
        }
      });
    });

    //Au submit du formulaire

  },
  showCountriesByRegion: () => { //Selectionne ou déseletionne les pays selon la region selectionnée
    // RAZ de tous les pays selected-- par région sauf les pays selectionnés individuellement
    FindUs.eltAllCountries.querySelectorAll(`li button.selected--`).forEach((item) => {
      const isInArray = FindUs.countriesSelectedCurrent.find(countries => countries.slug === item.dataset.country);
      if(!isInArray || (isInArray && isInArray === 0)) {
        item.classList.remove('selected--');
      }
    });

    if(FindUs.regionSelectedCurrent) { //il y a un filtre ==> On passe les pays en selected;
      FindUs.eltAllCountries.querySelectorAll(`li button[data-region=${FindUs.regionSelectedCurrent}`).forEach((item) => {
        item.classList.add('selected--');
      });
    }

    FindUs.countFiltersSelected();
  },
  showSelectedCountryList: () => {//Affiche la liste des pays sélectionnés
    const eltCountrySelected = FindUs.eltPopin.querySelector('.c-findus__countries--selected');
    let html = "";
    if(FindUs.countriesSelectedCurrent.length > 0) {
      html += '<ul>';
      FindUs.countriesSelectedCurrent.forEach((item) => {
        html += `<li><button data-country=${item.slug} class="selected--">${item.name}</button></li>`;
      });
      html += '</ul>';
    }
    eltCountrySelected.innerHTML = html;
    FindUs.deleteCountrySelected();
    FindUs.countFiltersSelected();
  },
  getSelectedRegion: () => {//Met en évidence la région sélectionnée
    if(FindUs.eltAllRegion.querySelector('button.selected--')) {
      FindUs.eltAllRegion.querySelector('button.selected--').classList.remove('selected--');
    }

    if(FindUs.regionSelectedCurrent && FindUs.regionSelectedCurrent!='') {
      FindUs.eltAllRegion.querySelector('button[data-region="'+FindUs.regionSelectedCurrent+'"]').classList.add('selected--')
    }
    FindUs.showCountriesByRegion();
  },
  getSelectedCountries: () => {//Met en évidence les countries selectionnés
    if(FindUs.eltAllRegion.querySelector('button.selected--')) {
      FindUs.eltAllRegion.querySelector('button.selected--').classList.remove('selected--');
    }

    if(FindUs.regionSelectedCurrent && FindUs.regionSelectedCurrent!='') {
      FindUs.eltAllRegion.querySelector('button[data-region="'+FindUs.regionSelectedCurrent+'"]').classList.add('selected--')
    }
    FindUs.showCountriesByRegion();
  },
  deleteCountrySelected: () => { //Delete un pays sélectionné
    FindUs.eltPopin.querySelectorAll('.c-findus__countries--selected li button').forEach((elt) => {
      elt.addEventListener('click', (e) => {
        FindUs.countriesSelectedCurrent = FindUs.countriesSelectedCurrent.filter(item => item.slug!=e.target.dataset.country);
        const countryToRemove = FindUs.eltAllCountries.querySelector(`button[data-country=${e.target.dataset.country}`);
        if(FindUs.regionSelectedCurrent === "" || countryToRemove.dataset.region != FindUs.regionSelectedCurrent) {//remove class selected sauf si présent ds une region selectionnée
          FindUs.eltAllCountries.querySelector(`button[data-country=${e.target.dataset.country}`).classList.remove('selected--');
        }
        FindUs.showSelectedCountryList();
      })
    })
  },
  listSearchCountry: (countries, letters) => {
    let html = ''
    if(countries) {
      html += '<ul>';
      for (var key in countries) {
        html += `<li><button data-country="${countries[key]['country-slug']}">${countries[key]['country-name'].toLowerCase().replace(letters.toLowerCase(), '<strong>'+letters+'</strong>')}</button></li>`;
      };
      html += '</ul>';
    }
    else {
      html = FindUs.eltFormCountry.dataset.noresults;
    }
    FindUs.eltCountriesResult.innerHTML = html;
    FindUs.eventSearchCountry();
  },
  eventSearchCountry: () => {
    FindUs.eltCountriesResult.querySelectorAll('li button').forEach((elt) => {
      elt.addEventListener('click', (e) => {
        e.preventDefault();
        if(!e.target.classList.contains('selected--')) {
          FindUs.eltAllCountries.querySelector(`li button[data-country=${e.target.dataset.country}`).classList.add('selected--');
          FindUs.countriesSelectedCurrent.push({slug:e.target.dataset.country, name:e.target.innerHTML});
          FindUs.showSelectedCountryList();
          FindUs.eltFormCountry.querySelector('input').value="";
          FindUs.eltFormCountry.closest('.c-findus__country').classList.remove('open--');
          FindUs.eltCountriesResult.innerHTML ="";//reset du contenu de resultats
        }
      });
    })
  },
  countFiltersSelected:() => {
    FindUs.nbFilters = 0;
    if(FindUs.regionSelectedCurrent!='') { FindUs.nbFilters = 1; }
    FindUs.nbFilters = FindUs.nbFilters + FindUs.countriesSelectedCurrent.length;
    if(FindUs.nbFilters > 0) {
      FindUs.btnFilters.classList.add('active--');
      FindUs.btnFilters.querySelector('.number').innerHTML = `(${FindUs.nbFilters})`;
      document.getElementById('btn-clear').querySelector('span').innerHTML=`(${FindUs.nbFilters})`;
      FindUs.eltPopin.querySelector('.c-findus__region--btn').classList.add('show--');
    }
    else {
      FindUs.btnFilters.classList.remove('active--');
      FindUs.btnFilters.querySelector('.number').innerHTML = ``;
      FindUs.eltPopin.querySelector('.c-findus__region--btn').classList.remove('show--');
    }
  },
  closePopin: () => {
    FindUs.eltPopin.classList.remove('show--');
      const timer = setTimeout(() => {
        FindUs.eltResultsContent.classList.remove('hide--');
        clearTimeout(timer);
      }, 300);

      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = 'auto'
  },
  clearAll: () => {
    FindUs.regionSelected="";
    FindUs.regionSelectedCurrent="";
    FindUs.countriesSelected=[];
    FindUs.countriesSelectedCurrent=[];

    FindUs.loadAgencies(FindUs.serviceSelected, '', '');

    FindUs.eltPopin.querySelector('.c-findus__countries--selected').innerHTML = "";//suppr la liste des pays selectionnés

    if(FindUs.eltAllRegion.querySelector('li button.selected--')) {//suppr le selected sil y a une region de selected--
      FindUs.eltAllRegion.querySelector('li button.selected--').classList.remove('selected--');
    }
    FindUs.eltAllCountries.querySelectorAll('li button.selected--').forEach((elt) => {//suppr le selected sur tous les pays selectionnés
      elt.classList.remove('selected--');
    })
    FindUs.countFiltersSelected();
  },
  clickAccordion(elt) {
    elt.closest('.c-findus__agencies--item').classList.toggle('open--')
    
  }
}
export default FindUs;