import Accordeon from "./components/accordeon";
import Anchors from "./components/anchors";
import AnimGsap from "./components/animGsap";
import ColorThemeDetector from "./components/colorThemeDetector";
import Contact from "./components/contact";
import Counter from "./components/counter";
import DareAnimations from "./components/homepage/dareAnimations";
import FindUs from "./components/findUs"
import Gallery from "./components/gallery";
import GlobalModal from "./components/globalModal";
import Header from "./components/header"
import HeroAnimations from "./components/homepage/heroAnimations";
import Menu from "./components/menu";
import Modal from './components/modal';
import OurWorkAnimations from "./components/homepage/ourWorkAnimations";
import Press from "./components/press";
import Prosumer from "./components/prosumerReports"
import ReduceTitle from './components/reduceTitle';
import RiseUp from "./components/riseup";
import RiseUpDecal from "./components/riseupDecal";
import Riselinear from "./components/riselinear";
import ScrollTop from "./components/scrollTop";
import ServicesAnimations from "./components/homepage/servicesAnimations";
import Slider from "./components/slider";
import TabModuleAnimations from "./components/homepage/tabModuleAnimations";
import TextAppear from "./components/textAppear";
import Trombi from "./components/trombi";
import Video from "./components/video";
import VideoPopin from "./components/videoPopin";
import Bourse from "./components/bourse";
import BourseChart from "./components/bourseChart";
// Blocks Animations HP 





//Animations globales




// Composants 

const main = {
    init: function () {
        // document.fonts.ready.then(function () {
        ReduceTitle.init();
        TextAppear.init();
        AnimGsap.init();
        Anchors.init();
        RiseUp.init();
        RiseUpDecal.init();
        Riselinear.init();
        HeroAnimations.init();
        TabModuleAnimations.init();
        OurWorkAnimations.init();
        ServicesAnimations.init();
        DareAnimations.init();
        FindUs.init();
        Counter.init();
        Slider.init();
        Accordeon.init();
        Header.init();
        Menu.init();
        Contact.init();
        ColorThemeDetector.init();
        Trombi.init();
        Press.init();
        Prosumer.init();
        Gallery.init();
        ScrollTop.init();
        Modal.init();
        Video.init();
        GlobalModal.init();
        VideoPopin.init();
        Bourse.init();
        BourseChart.init();
        // })
    }
}

export default main