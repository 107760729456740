'use strict'
// A sample component

const Redirection =  {
    init: function(){
        var elt = document.querySelectorAll('.data-url');
        if(elt.length>0) {
            elt.forEach( function(element, index) {
                element.addEventListener('click', function(e) {
                    e.preventDefault();
                    document.location.href=element.dataset.url;
                })
            });
        }

        var elt = document.querySelectorAll('.data-download');
        if(elt.length>0) {
            elt.forEach( function(element, index) {
                element.addEventListener('click', function(e) {
                    e.preventDefault();
                    window.open( element.dataset.url );
                })
            });
        }
    }
}


export default Redirection;
