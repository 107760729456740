'use strict'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const OurWorkAnimations =  {
    init: function(){
      if (document.querySelector('.block-services')) {
        gsap.registerPlugin(ScrollTrigger);
        this.scrollTriggerServices();
      }
    },
    scrollTriggerServices: function() {
        // Ajoute la class visible lorsque le block service est dans la vue 
        const servicesList = document.querySelector('.block-services__right__list');
        gsap.to('#services', {
            scrollTrigger: {
                trigger: '#services',
                start: "top center",
                onEnter: (self) => {
                    self.trigger.classList.add('visible')
                    if (servicesList) {
                      const items = servicesList.querySelectorAll('li a');
                      items.forEach((item, i) => {
                        item.style.transitionDelay = `${0.6 + (0.1 * i)}s`
                      })
                    }
                },
                onLeaveBack: (self) => {
                  self.trigger.classList.remove('visible');
                  if (servicesList) {
                    const items = servicesList.querySelectorAll('li a');
                    items.forEach((item, i) => {
                      item.style.transitionDelay = `0s`
                    })
                  }
              }
            }
        })
        let mm = gsap.matchMedia();
          mm.add("(min-width: 991px)", () => {
              gsap.to('#services', {
                scrollTrigger: {
                  trigger: '#services',
                  start: "-110px 0",
                  // markers:true,
                  end: "+=1200",
                  pin: true,
                  pinSpacing: false,
                  scrub: 0.8,
                }
              });
          })
    },
}

export default OurWorkAnimations;