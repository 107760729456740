'use strict';
import Slider from "./slider";
const Video = {
    els: null,
    init: function () {
        Video.els = document.querySelectorAll('.c-videoPopin');
        if (Video.els && Video.els.length > 0) {
            Video.els.forEach(el => {
                Video.managePoster(el);
            });
        }
    },
    managePoster: function (el) {
        const poster = el.querySelector('.c-videoPopin__player-poster');
        if(poster){
            const cursor = poster.querySelector('.smart-cursor');
            if(poster)
                poster.addEventListener('click', () => {
                    el.querySelector('.c-videoPopin__content').classList.add("open--");
                    if(poster.dataset.typeVideo === "youtube" && tarteaucitron && tarteaucitron.state.youtube) {
                        let src = el.querySelector('iframe').getAttribute('src').replace('autoplay=0', '').replace('muted=0', '').replace('playsinline=0', '')
                        if(src.includes('?')){
                            src+="&autoplay=1&playsinline=1"
                        }
                        else {
                            src+="?autoplay=1&playsinline=1"
                        }
                        el.querySelector('.youtube_player').innerHTML = `
                            <iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; playsinline; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="100%" height="100%" src="${src}"></iframe>`
                    }
                    else if(poster.dataset.typeVideo === "vimeo" && tarteaucitron && tarteaucitron.state.vimeo) {
                        let src = el.querySelector('iframe').getAttribute('src').replace('autoplay=0', '').replace('muted=0', '').replace('playsinline=0', '');
                        if(src.includes('?')){
                            src+="&autoplay=1&autopause=0"
                        }
                        else {
                            src+="?autoplay=1&autopause=0"
                        }
                        el.querySelector('.vimeo_player').innerHTML = `
                            <iframe width="100%" height="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen=""  allow="autoplay" allowfullscreen="" src="${src}"></iframe>`
                    }
                    else if(poster.dataset.typeVideo === "dailymotion" && tarteaucitron && tarteaucitron.state.dailymotion) {
                        let src = el.querySelector('iframe').getAttribute('src').replace('autoplay=0', '').replace('muted=0', '').replace('playsinline=0', '');
                        if(src.includes('?')){
                            src+="&autoplay=1&autopause=0&mute=0"
                        }
                        else {
                            src+="?autoplay=1&autopause=0&mute=0"
                        }
                        el.querySelector('.dailymotion_player').innerHTML = `
                            <iframe width="100%" height="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen=""  allow="autoplay" allowfullscreen="" src="${src}"></iframe>`
                
                    }
                    else if(poster.dataset.typeVideo === "mp4") {
                        el.querySelector('.c-videoPopin__content video').play();
                    }
                });
            if (cursor) {
                Slider.smartCursorPosition(poster, cursor);
            }

            const closeButton = el.querySelector('.c-videoPopin__close');
            if (closeButton) {
                closeButton.addEventListener('click', () => {
                    el.querySelector('.c-videoPopin__content').classList.remove("open--");

                    const video = el.querySelector('.c-videoPopin__content video'); //video mp4
                    if (video) {
                        video.pause();
                        video.currentTime = 0;
                    }

                    //cas external video
                    let src = el.querySelector('iframe').getAttribute('src').replace('autoplay=1', '').replace('muted=1', '').replace('playsinline=1', '');
                    console.log(src)
                    
                    el.querySelector('iframe').src = src;
                });
            }
        }
    }
};

export default Video;